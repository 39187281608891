.Box {
  display: flex;
}
.CartBox {
  width: 30%;
  height: 100%;
}
.Cart {
  position: fixed;
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  height: 300px;
  border-radius: 20px;
  padding: 1rem 4rem 1rem 2rem;
  margin: 6.5rem 0;
}
.Kassa {
  margin-bottom: 0;
}
.Inputs {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 5rem 0;
}
.CartMobile {
  display: none;
}
.Sum {
  margin-top: auto;
}

@media (max-width: 800px) {
  .Box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Cart {
    display: none;
  }
  .CartMobile {
    display: block;
    background-color: lightblue;
    height: 200px;
    margin: 3rem 1rem 3rem 0;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 1rem;
  }
  .Inputs {
    width: 90%;
    margin: 6rem 0 3rem 0;
  }
}

.NrDeltagare {
  margin: 2rem 0;
}
.formControl {
  display: block;
  width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.label {
  font-size: 1.1rem;
}
.InputElement {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.Button {
  font-size: 1.5rem;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #007bff;
  padding: 20px;
  margin-top: 2rem;
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}

.Button:hover {
  background: #434343;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}
