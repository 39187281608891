.Payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.logo {
  width: 200px;
  margin-bottom: 5rem;
  margin-top: auto;
}
