.Button {
  font-size: 1.5rem;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #007bff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}

.Button:hover {
  background: #434343;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.Checkout {
  margin-top: 8rem;
  display: flex;
  width: 500px;
  align-items: center;
  max-width: 90%;
  flex-direction: column;
}
.Complete {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PaymentError {
  color: red;
}
