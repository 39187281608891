.Header {
  background-color: black; /* fallback color */
  background-color: rgba(0, 194, 219, 1);
  background-image: url(../assets/images/Stocken2023_1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 900px;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  object-fit: fill;
}

@media (max-width: 600px) {
  .Header {
    height: 700px;
  }
}

.HeaderBox {
  height: 100%;
  width: 800px;
  max-width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.shadow {
  -moz-box-shadow: inset 0px 34px 22px -7px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0px 34px 22px -7px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px 80px 70px -7px rgba(0, 0, 0, 0.8);
}

.anmalanDiv {
  margin: auto 0 0 0;
}

.AnmalanButton {
  display: inline-block;
  padding: 0.7rem 3rem;
  background-color: rgba(0, 194, 219, 1);
  color: white;
  font-size: 2.3rem;
  height: 40px;
  width: 300px;
  max-width: 40%;
  border: none;
  border-radius: 10px;
  transition: 400ms 200ms;
  font-weight: 100;
}

.AnmalanButton:hover {
  background-color: rgba(0, 194, 219, 0.6);
  transition: 200ms 50ms;
}

.AnmalanButtonClosed {
  display: inline-block;
  padding: 0.7rem 3rem;
  background-color: rgba(0, 194, 219, 0.6);
  text-decoration: line-through;
  color: white;
  font-size: 2.3rem;
  height: 40px;
  width: 300px;
  max-width: 40%;
  border: none;
  border-radius: 10px;
  transition: 400ms 200ms;
  font-weight: 100;
}

@media (max-width: 600px) {
  .AnmalanButton {
    font-size: 1.8rem;
    max-width: 65%;
    font-weight: 300;
  }
  .AnmalanButtonClosed {
    font-size: 1.8rem;
    max-width: 65%;
    font-weight: 300;
  }
}

.H1 {
  font-size: 4rem;
  color: white;
  font-weight: 300;
}
@media (max-width: 600px) {
  .H1 {
    margin-top: 5rem;
    font-size: 3rem;
    font-weight: 400;
  }
}

.BreadText {
  color: white;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .BreadText {
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.H4 {
  color: white;
  font-weight: 300;
  margin-top: 1rem;
  font-size: 2rem;
}

@media (max-width: 600px) {
  .H4 {
    color: white;
    font-weight: 400;
    margin-top: 1rem;
    font-size: 2rem;
  }
}
