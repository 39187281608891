.HeaderButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 20px auto;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
